import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const navbarRef = useRef(null);

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    // Close the navbar using Bootstrap's collapse API
    const navbar = new window.bootstrap.Collapse(navbarRef.current, {
      toggle: false,
    });
    navbar.hide(); // Programmatically hide the navbar

    // Navigate to the search results page with the search query
    if (searchTerm.trim()) {
      navigate(`/search/${searchTerm}`);
    }
  };

  return (
    <nav className="navbar fixed-top navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <a className="navbar-brand" href="/#">
          C.W. Hambleton
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
          ref={navbarRef}
        >
          <ul className="navbar-nav me-auto mb-2 mx-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="/#featured">
                Featured
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                rel="noreferrer"
                href="https://beingrefactored.com"
                target="_blank"
              >
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#trailers">
                Trailers
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Fiction
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/#fiction">
                  All Fiction Titles
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="/series/daysofnoah">
                  The Days of Noah Series
                </a>
                {/* <a className="dropdown-item" href="/series/encounter">
                  The Encounter Series
                </a> */}
                <a className="dropdown-item" href="/series/hazikaron">
                  The HaZikaron Series
                </a>
                <a className="dropdown-item" href="/series/sonsofliberty">
                  The Sons of Liberty
                </a>
                <a className="dropdown-item" href="/series/timeofjacobstrouble">
                  The Time of Jacob's Trouble
                </a>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#nonfiction">
                NonFiction
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#singles">
                Singles
              </a>
            </li>
          </ul>
          <form className="d-flex" role="search" onSubmit={handleSearchSubmit}>
            <input
              className="form-control me-2"
              name="q"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm ?? ""}
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button className="btn btn-outline-success" type="submit">
              Search
            </button>
          </form>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
