import React from "react";

import BigPicture from "bigpicture";

const TrailerView = ({ itemId, videoId }) => {
  const showVideo = (targetId, videoId) => {
    if (!!videoId && videoId.indexOf("https") < 0)
      BigPicture({
        el: document.querySelector("#" + targetId),
        ytSrc: videoId,
      });
    else {
      window.open(videoId, "_blank");
    }
  };

  return (
    <div
      id={`videoButton-${itemId}`}
      onClick={() => showVideo(`videoButton-${itemId}`, videoId)}
    >
      <img
        alt={itemId}
        className="trailer"
        src={`/assets/trailers/${itemId}_trailer.jpg`}
      />
    </div>
  );
};

export default TrailerView;
