//import logo from './logo.svg';
import React from 'react';
import NavBar from './components/navBar';
import Footer from './components/footer';

import './App.css';
import AppRoutes from './AppRoutes';

const App = () => {

  return (
    <div className="App">
      <NavBar />
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;
