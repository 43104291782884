import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { isNotNullOrEmpty, convertJsonTextToHtml } from "../lib/utilities";
import BookList from "./bookList";
import TrailerView from "./trailerView";
import NavBackButton from "./navBackButton";
import * as api from "../lib/api";
import { BoxSetNumber } from "../lib/constants";

const BookView = ({ id, extraContent = true }) => {
  const [item, setItem] = useState();
  const { slug } = useParams();

  useEffect(() => {
    const fetchBook = (itemId) => {
      const results = api.getBooksById(itemId);
      if (isNotNullOrEmpty(results)) {
        let book = results[0];

        if (isNotNullOrEmpty(book.series)) {
          // get all books in series, add to book obj
          const series = api.getBookItemsBySeriesId(book.series[0]);
          const seriesItems = api.getSeriesById(book.series[0]);
          const seriesItem = isNotNullOrEmpty(seriesItems)
            ? seriesItems[0]
            : { title: "Series" };
          book = {
            ...book,
            seriesBooks: series.sort((a, b) => a.seriesNum - b.seriesNum),
            seriesTitle: seriesItem.title,
          };
        }

        // get related books, add to book obj
        if (isNotNullOrEmpty(book.related)) {
          let relatedItems = [];
          book.related.forEach((relId) => {
            const relBook = api.getBooksById(relId);
            if (isNotNullOrEmpty(relBook)) {
              relatedItems.push(relBook[0]);
            }
          });
          book.relatedItems = [...relatedItems];
        }

        setItem(book);
      }
    };

    fetchBook(id ?? slug);
  }, [slug, id]);

  if (!item) return null;
  const seriesSuffix =
    isNotNullOrEmpty(item.series) ?? ["tojt", "tsol"].includes(item.series[0])
      ? "Trilogy"
      : "Series";

  const seriesBookSubTitle =
    isNotNullOrEmpty(item.seriesTitle) && item.seriesNum !== BoxSetNumber
      ? `Book ${item.seriesNum} of ${item.seriesTitle}`
      : "";

  const getAudioBookUrl = (audioId) => {
    if (!!audioId && audioId.indexOf("http") < 0) {
      return `https://www.amazon.com/dp/${audioId}`;
    }

    return audioId;
  };

  return (
    <div className={`section ${extraContent ? "contents" : ""}`}>
      <div className="content">
        <div className="row">
          <div className="col-12 col-md-8">
            <h2 className="book-view-title">{item.title}</h2>
            <h5>{item.subTitle ?? seriesBookSubTitle ?? ""}</h5>
            <p
              dangerouslySetInnerHTML={{
                __html: convertJsonTextToHtml(item.description),
              }}
            />
          </div>
          <div className="col-12 col-md-4">
            {extraContent && <NavBackButton />}
            <div key={item.id}>
              <div className="book-frame-large">
                <img
                  alt={item.title}
                  className="book-large"
                  src={`/assets/books/${item.id}.jpg`}
                />
              </div>
              <p>Published: {item.pubDate}</p>
              <div className="book-view-buy">
                <a
                  title={item.title}
                  href={`https://www.amazon.com/dp/${item.amazonId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="buy from amazon"
                    className="amazon-button-image"
                    src="/assets/misc/amazon-black.jpg"
                  />
                </a>
              </div>
              {isNotNullOrEmpty(item.audioId) && (
                <div className="audio-view-buy">
                  <a
                    title={item.title}
                    href={getAudioBookUrl(item.audioId)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="buy audiobook"
                      className="amazon-button-image"
                      src="/assets/misc/audio-book.jpg"
                    />
                  </a>
                </div>
              )}
            </div>
          </div>
          {extraContent && (
            <div className="row">
              <div className="col-12">
                {isNotNullOrEmpty(item.trailerUrl) && (
                  <div>
                    <h5 style={{ marginTop: 30 }}>Watch the Trailer!</h5>
                    <TrailerView itemId={item.id} videoId={item.trailerUrl} />
                  </div>
                )}
                {isNotNullOrEmpty(item.keywords) && (
                  <p className="d-none d-md-block">
                    Keywords: <em>{item.keywords.join(", ")}</em>
                  </p>
                )}
                {isNotNullOrEmpty(item.seriesBooks) && (
                  <>
                    {isNotNullOrEmpty(item.seriesTitle) && (
                      <h4 style={{ marginTop: 40 }}>{item.seriesTitle}</h4>
                    )}
                    <BookList items={item.seriesBooks} />
                  </>
                )}
                {isNotNullOrEmpty(item.relatedItems) && (
                  <>
                    <h4 style={{ marginTop: 40 }}>Related Items</h4>
                    <BookList items={item.relatedItems} />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookView;
