//import logo from './logo.svg';
import React from 'react';
import Content from './components/content';

import './App.css';
import BookView from './components/bookView';
import SeriesView from './components/seriesView';
import SearchResults from './components/searchResults';
import NotFound from './components/notFound';
import {
  Routes,
  Route
} from "react-router-dom";

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Content />} />
      <Route path="/book/:slug" element={<BookView />} />
      <Route path="/book/authoroflife" element={<BookView id={'authoroflife'} />}  />
      <Route path="/book/ezekielwatch" element={<BookView id={'ezekielwatch'} />} />
      <Route path="/book/walkswithrich" element={<BookView id={'walkswithrich'} />} />
      <Route path="/series/daysofnoah" element={<SeriesView id={'noah'}  />} />
      <Route path="/series/hazikaron" element={<SeriesView id={'hazikaron'} />} />
      <Route path="/series/encounter" element={<SeriesView id={'encounter'} />} />
      <Route path="/series/sonsofliberty" element={<SeriesView id={'tsol'} />} />
      <Route path="/series/timeofjacobstrouble" element={<SeriesView id={'tojt'} />} />
      <Route path="/search/:query" element={<SearchResults />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
