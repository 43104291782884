import React from "react";
import { Link } from "react-router-dom";
import TrailerView from "./trailerView";

const Trailers = ({ title, tag, items }) => {
  return (
    <div className="section">
      <div className="heading" id={tag}>
        <h3>{title}</h3>
      </div>
      <div className="content">
        <div className="row">
          {items.map((item) => (
            <div className="col" key={item.id}>
              <TrailerView itemId={item.id} videoId={item.trailerUrl} />
              <div className="trailer-link">
                <Link to={`/book/${item.id}`}>{item.title}</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trailers;
