import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { isNotNullOrEmpty } from "../lib/utilities";
import TrailerView from "./trailerView";
import BookView from "./bookView";
import * as api from "../lib/api";

const SeriesView = ({ id }) => {
  const [series, setSeries] = useState([]);

  const { slug } = useParams();

  useEffect(() => {
    const fetchSeries = (seriesId) => {
      const results = api.getSeriesById(seriesId);

      if (isNotNullOrEmpty(results)) {
        let bookSeries = results[0];

        //get all books in series, add to book obj
        const seriesBooks = api.getBookItemsBySeriesId(seriesId);
        const sortedBooks = seriesBooks.sort(
          (a, b) => a.seriesNum - b.seriesNum
        );
        bookSeries.items = [...sortedBooks];

        //   // get related books, add to book obj
        //   if (isNotNullOrEmpty(book.related)) {
        //     let relatedItems = [];
        //     book.related.forEach((relId) => {
        //       const relBook = api.GetBooksById(relId);
        //       if (isNotNullOrEmpty(relBook)) {
        //         relatedItems.push(relBook[0]);
        //       }
        //     });
        //     book.relatedItems = [...relatedItems];
        //   }

        setSeries(bookSeries);
      }
    };
    fetchSeries(id ?? slug);
  }, [slug, id]);

  if (!series) return null;

  return (
    <div className="section contents">
      <div className="content">
        <div className="row">
          <div className="col">
            <h2 className="book-view-title">{series.title}</h2>

            {isNotNullOrEmpty(series.items) &&
              series.items.map((item, index) => {
                return (
                  <>
                    <BookView id={item.id} extraContent={false} />
                    {index < series.items.length - 1 && <hr />}
                  </>
                );
              })}

            {isNotNullOrEmpty(series.trailerUrl) && (
              <div>
                <h5>Watch the Trailer!</h5>
                <TrailerView itemId={series.id} videoId={series.trailerUrl} />
              </div>
            )}
            {isNotNullOrEmpty(series.keywords) && (
              <p>
                Keywords: <em>{series.keywords.join(", ")}</em>
              </p>
            )}
            {/*            
            {isNotNullOrEmpty(item.relatedItems) && (
              <>
                <h4>Related Items</h4>
                <BookList items={item.relatedItems} />
              </>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeriesView;
